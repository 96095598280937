import { APP_CONFIG } from "../../config";
import {
  ClientDeviceInfo,
  InstallCookie,
} from "../../services/Device/Device.interface";
import { Language } from "../../utils/i8n";
interface DeviceState extends ClientDeviceInfo {
  env: string;
  initialized: boolean;
  cookieAgreement: boolean;
  languageCode: Language;
}

const defaulteDeviceState: DeviceState = {
  initialized: false,
  cookieAgreement: false,
  languageCode: 'EN',
  env: APP_CONFIG.environment,
};

export { defaulteDeviceState, DeviceState, InstallCookie };
