import { View, setState, state } from '..';
import { SiteHome } from './home/homeView.interface';
import { SiteShell } from './shell/shellView.interface';
import { SiteBlog } from './blog-page/blogView.interface';
import { SiteServices } from './services-page/servicesView.interface';
import { SiteContact } from './contact-page/contactView.interface';
import { SiteServiceDetails } from './service-details/serviceDetailsView.interface';
import { SiteBlogArticles } from './blog-articles/blogArticlesView.interface';


type Views = {
  siteHome: View<SiteHome>;
  siteShell: View<SiteShell>;
  siteBlog: View<SiteBlog>;
  siteServices: View<SiteServices>;
  siteContact: View<SiteContact>;
  siteServiceDetails: View<SiteServiceDetails>;
  siteBlogArticles: View<SiteBlogArticles>;
}

const defaultSiteViewsState: Views = {
  siteHome: null,
  siteShell: null,
  siteBlog: null,
  siteServices: null,
  siteContact: null,
  siteServiceDetails: null,
  siteBlogArticles: null,
};
function updateView(payload: Partial<Views>, effect: string) {
  setState("VIEWS", { VIEWS: { ...state.VIEWS, ...payload } }, effect);
}
function updateViewElement<K extends keyof Views, E extends keyof Views[K]>(view: K, element: E, payload: Views[K][E], effect: string) {
  console.log('Updating par of view', element)
  setState('VIEWS', { VIEWS: { ...state.VIEWS, [view]: { ...state.VIEWS[view], [element]: payload } } }, effect);
}
export { Views, defaultSiteViewsState, updateView, updateViewElement };