import { NavigationData } from "../services/Navigation/Navigation.interface";

  export const SiteNavigation: NavigationData = {
    root: "app-page-home",
    menu: {
      restriction: "completedProfile",
      url: "/",
      type: "menu",
      subRoutes: {
        "services": {
          component: "site-page-home", // No direct component since it's now a dropdown
          icon: "",
          url: "/", // Use "#" as placeholder
          dropdownItems: [
            {
              name: "All Services",
              labels: { 
                EN: "All Services", 
              FR: "Tous les services", 
              NL: "Alle diensten" },
              url: "/services",
              component: "site-page-services"
            },
            // Add more dropdown items here if necessary
          ]
        },
        "blog": {
          component: "site-page-blog",
          icon: "",
          url: "/blog"
        },
        "contact": {
          component: "site-page-contact",
          icon: "",
          url: "/contact"
        },
      },
      labels: {
        EN: { 
          "services": "Services", 
          "blog": "Advices", "contact": 
          "Contact Us" },
        FR: { 
          "services": "Services", 
          "blog": "Conseils", "contact": 
          "Contactez-nous" },
        NL: { 
          "services": "Diensten", 
          "blog": "Advies", "contact": 
          "Contacteer ons" }
      }
    },
    routes: {
      "serviceDetailPage1": { 
        url: "/services/solutions-electricite", 
        component: "site-page-service-detail" 
      },
      "serviceDetailPage2": { 
        url: "/services/solutions-plomberie", 
        component: "site-page-service-detail-2" 
      },
      "serviceDetailPage3": { 
        url: "/services/solutions-vertes", 
        component: "site-page-service-detail-3" 
      },
      "serviceDetailPage4": { 
        url: "/services/solutions-renovation", 
        component: "site-page-service-detail-4" 
      },
      "serviceDetailPage5": { 
        url: "/services/solutions-nettoyage", 
        component: "site-page-service-detail-5" 
      },
      "blogPage": { 
        url: "/blog", 
        component: "site-page-blog" 
      },
      "articlePage1": { 
        url: "/blog/conseils-de-securite-electrique-pour-votre-maison", 
        component: "site-page-blog-article" 
      },
      "articlePage2": { 
        url: "/blog/conseils-de-securite-en-plomberie-pour-votre-maison", 
        component: "site-page-blog-article-2" 
      },
      "articlePage3": { 
        url: "/blog/conseils-de-prevention-en-moisissures-dans-votre-maison", 
        component: "site-page-blog-article-3" 
      },
      "articlePage4": { 
        url: "/blog/conseils-pour-une-isolation-efficace-de-votre-maison", 
        component: "site-page-blog-article-4" 
      },
      "articlePage5": { 
        url: "/blog/conseils-pour-une-renovation-reussie-de-votre-maison", 
        component: "site-page-blog-article-5" 
      },
      "articlePage6": { 
        url: "/blog/conseils-pour-un-nettoyage-efficace-de-votre-maison", 
        component: "site-page-blog-article-6" 
      },
      "contactPage": { 
        url: "/contact", 
        component: "site-page-contact" 
      },
      "terms": { 
        url: "/conditions-generales", 
        component: "site-page-terms" 
      },
      "policy": { 
        url: "/politique-de-confidentialite", 
        component: "site-page-policy" 
      }
    },
    routeGuards: {},
    redirects: []
  };
  
