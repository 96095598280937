type ErrorState = {
  latestEventTime: number;
  latestErrorUUID: string;
  stateName: string;
  errors: Array<Error>;
  maxErrorHistory: number;
};

const defaultState: ErrorState = {
  latestEventTime: null,
  latestErrorUUID: null,
  stateName: null,
  errors: [],
  maxErrorHistory: 10,
};

export { defaultState, ErrorState };
