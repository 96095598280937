import { SupportedLanguages } from "../utils/i8n";
import { AppConfig } from "./config.interface";
const APP_CONFIG: AppConfig = {
  version: "prod-0.1",
  environment: "develop",
  apiHost: "https://www.master-7rqtwti-yklmlymh5qs6s.de-2.platformsh.site",
  languages: SupportedLanguages,
  selectedLanguage: "FR",
  sentry: {
    dsn: "https://7015c3c6c40e475785437ffe6bd1d938@o471806.ingest.sentry.io/5504311",
  },
};
export default APP_CONFIG;
