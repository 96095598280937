import { logger } from "./logger";

import { omit } from 'ramda';

const SupportedLanguages = {
  EN: {
    'code': 'en',
    'name': 'English',
    'getLocale': async () => {
      const locale = import('date-fns/locale/en-GB');
      return locale;
    }
  },
  FR: {
    'code': 'fr',
    'name': 'Francais',
    'getLocale': async () => {
      const locale = import('date-fns/locale/en-GB');
      return locale;
    }
  },
  NL: {
    'code': 'nl-BE',
    'name': 'Netherlands',
    'getLocale': async () => {
      const locale = import('date-fns/locale/en-GB');
      return locale;
    }
  },
};
type Language = keyof typeof SupportedLanguages;

type LocalData<D, T> = D & { translations: Record<Language, T> };

function getLanguageData<D, T>(
  data: LocalData<D, T>,
  languageCode: string
): { data: D, translated: T } {
  const { translations } = data;
  const strippedData = omit(['translations'], data) as D;
  const langData = translations[languageCode];
  if (!langData) {
    logger.warn(
      `Translation data for language ${languageCode} is missing. Setting language to default locale:${languageCode}`
    );
    return { data: strippedData, translated: langData };
  }
  return { data: strippedData, translated: langData };;
}

export { LocalData, SupportedLanguages, Language, getLanguageData };
